import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import APIManager from "../scripts/APIManager";
import { AccountContext } from "../Contexts";

export default function DiscordAuth() {
  const { signedIn } = useContext(AccountContext);
  const [discordCode, setDiscordCode] = useState(null);
  const [waitForDiscordLink, setWaitForDiscordLink] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const redirect_uri =
    window.location.protocol +
    "//" +
    window.location.host +
    (location.pathname === "/"
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname.replace(location.pathname, "")) +
    "/home/discord_auth";

  useEffect(() => {
    if (waitForDiscordLink === null) {
      const urlParams = new URLSearchParams(location.search);
      let newCode = urlParams.get("code");
      if (newCode) {
        setDiscordCode(newCode);
        setWaitForDiscordLink(true);
      } else setWaitForDiscordLink(false);
    }
  }, [location, waitForDiscordLink]);

  const sendDiscordCode = useCallback(
    () =>
      APIManager.sendRequest(
        "authenticate_discord",
        {
          discord_code: discordCode,
          redirect_uri,
        },
        true
      ).then(({ success }) =>
        success
          ? navigate("/home", { replace: true })
          : setWaitForDiscordLink(false)
      ),
    [discordCode, redirect_uri, navigate]
  );

  useEffect(() => {
    if (signedIn && discordCode) sendDiscordCode();
  }, [signedIn, discordCode, sendDiscordCode]);

  return (
    <div className="flex flex-row justify-center items-center w-screen h-screen">
      <div>
        {waitForDiscordLink === false ? (
          <>
            <h1 className="text-dark-gray-base">
              Discord code missing or invalid.
            </h1>
            <p className="text-dark-gray-base">Please try again.</p>
          </>
        ) : (
          <>
            <h1 className="text-dark-gray-base">Authenticating Discord.</h1>
            <p className="text-dark-gray-base">This may take a moment...</p>
          </>
        )}
      </div>
    </div>
  );
}
