import { DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  faCircleUser,
  faMoon,
  faPlusCircle,
  faSun,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Skeleton, Spin, Switch } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import constants from "../constants";
import { ThemeContext } from "../Contexts";
import APIManager from "../scripts/APIManager";
import AuthManager from "../scripts/AuthManager";
import AccessModal from "./AccessModal";
import Eye from "./Eye";
import AddRuleModal from "./moderation/AddRuleModal";

export default function Header(props) {
  const {
    className,
    tree,
    title,
    guildId,
    guilds,
    setActiveModal,
    isAdmin,
    subscribed,
    discordLinked,
    jiraLinked,
    redirectURI,
    permissions,
    bigSize,
    smallSize,
    expandLock,
    setExpandLock,
  } = props;
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const [guildsOpen, setGuildsOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [guildItems, setGuildItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/").filter((element) => element);

  const goToBilling = useCallback(() => {
    APIManager.sendRequest(
      "create_customer_portal_session",
      {
        return_address: window.location.href,
      },
      true
    ).then((data) => {
      if (data.success) window.location.href = data.url;
    });
  }, []);

  const navMap = useCallback(
    () =>
      tree?.map((tab, index) => (
        <div className="flex flex-col" key={`top-${index}`}>
          <div
            className={`flex flex-row items-center gap-2 h-8 transition-all rounded-lg ${
              tab.id === path[1] && path.length === 2
                ? "bg-primary-light-blue/20 nav-selected-text"
                : "hover:bg-black/5 dark:hover:bg-white/10 softer-text"
            }
            cursor-pointer`}
            onClick={() => navigate(`/${path[0]}/${tab.id}`)}
          >
            <div className="shrink-0 flex flex-row justify-center w-8">
              <FontAwesomeIcon
                icon={tab.icon || faCircleUser}
                size="lg"
                className={`${
                  tab.id === path[1] && path.length === 2
                    ? "nav-selected-text"
                    : "softer-text"
                }`}
              />
            </div>
            <p className="font-medium font-sans-header cursor-default whitespace-nowrap cursor-pointer">
              {tab.name}
            </p>
          </div>
          {tab.routes?.map(({ name, id, elements, icon }, index) =>
            elements?.length > 0 ? (
              <Dropdown
                menu={{
                  items: elements.map(({ name, id: elementId }) => ({
                    label:
                      elementId === "add_rule" ? (
                        <p
                          className={`flex flex-row items-center gap-2 ${
                            permissions?.["Create Rules"] ||
                            permissions?.["Suggest Moderation Edits"]
                              ? "opacity-100"
                              : "opacity-50"
                          }`}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} size="sm" />
                          <span>{name}</span>
                        </p>
                      ) : (
                        name
                      ),
                    key: elementId,
                  })),
                  selectedKeys:
                    tab.id === path[1] ? [`${path[2]}/${path[3]}`] : [],
                  onClick: ({ key }) => {
                    if (key === "add_rule") {
                      if (
                        permissions?.["Create Rules"] ||
                        permissions?.["Suggest Moderation Edits"]
                      )
                        setActiveModal("add_rule");
                    } else navigate(`/${path[0]}/${tab.id}/${key}`);
                  },
                }}
                className="relative"
                dropdownRender={(node) => (
                  <div className="absolute left-72 pl-4 -top-10 w-60">
                    {node}
                  </div>
                )}
                key={index}
              >
                <div
                  className={`flex flex-row justify-between items-center gap-2 h-8 transition-all rounded-lg ${
                    expanded ? "pl-8" : "pl-0"
                  } ${
                    elements
                      .map(({ id }) => id)
                      .includes(`${path[2]}/${path[3]}`) && tab.id === path[1]
                      ? "bg-primary-light-blue/20 nav-selected-text"
                      : "hover:bg-black/5 dark:hover:bg-white/10 softer-text"
                  }
              cursor-pointer`}
                >
                  <div className="shrink-0 flex flex-row justify-center w-8">
                    <FontAwesomeIcon
                      icon={icon || faCircleUser}
                      className={`${
                        elements
                          .map(({ id }) => id)
                          .includes(`${path[2]}/${path[3]}`) &&
                        tab.id === path[1]
                          ? "nav-selected-text"
                          : "softer-text"
                      }`}
                    />
                  </div>
                  <p className="text-sm font-medium font-sans-header cursor-default whitespace-nowrap cursor-pointer">
                    {name}
                  </p>
                  <div className="grow" />
                  <RightOutlined className="text-base text-shade-medium-dark mr-2" />
                </div>
              </Dropdown>
            ) : (
              <div
                className={`flex flex-row items-center gap-2 h-8 transition-all rounded-lg ${
                  expanded ? "pl-8" : "pl-0"
                } ${
                  path[2] === id && tab.id === path[1]
                    ? "bg-primary-light-blue/20 nav-selected-text"
                    : "hover:bg-black/5 dark:hover:bg-white/10 softer-text"
                }
              cursor-pointer`}
                key={index}
                onClick={() => navigate(`/${path[0]}/${tab.id}/${id}`)}
              >
                <div className="shrink-0 flex flex-row justify-center w-8">
                  <FontAwesomeIcon
                    icon={icon || faCircleUser}
                    className={`${
                      path[2] === id && tab.id === path[1]
                        ? "nav-selected-text"
                        : "softer-text"
                    }`}
                  />
                </div>
                <p className="text-sm font-medium font-sans-header cursor-default whitespace-nowrap cursor-pointer">
                  {name}
                </p>
              </div>
            )
          )}
        </div>
      )),
    [navigate, path, permissions, setActiveModal, tree, expanded]
  );

  const items = [
    {
      key: "user",
      label: <p className="text-base font-bold">{AuthManager.username}</p>,
    },
    ...((subscribed !== null && subscribed !== undefined && !subscribed) ||
    (discordLinked !== null && discordLinked !== undefined && !discordLinked)
      ? [
          {
            type: "divider",
          },
        ]
      : []),
    ...(discordLinked !== null && discordLinked !== undefined && !discordLinked
      ? [
          {
            key: "link_discord",
            label: (
              <Link
                to={`https://discord.com/api/oauth2/authorize?client_id=1140700067892826122&redirect_uri=${encodeURIComponent(
                  redirectURI + "/discord_auth"
                )}&response_type=code&scope=identify%20guilds%20guilds.members.read`}
              >
                Link Discord
              </Link>
            ),
          },
        ]
      : []),
    ...(jiraLinked !== null && jiraLinked !== undefined && !jiraLinked
      ? [
          {
            key: "link_jira",
            label: (
              <Link
                to={`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${process.env.REACT_APP_ATLASSIAN_CLIENT_ID}&scope=offline_access%20write%3Ajira-work%20read%3Ajira-work%20manage%3Ajira-configuration&redirect_uri=${encodeURIComponent(
                  redirectURI + "/atlassian_auth"
                )}&state=${
                  AuthManager.username
                }&response_type=code&prompt=consent`}
              >
                Link Jira
              </Link>
            ),
          },
        ]
      : []),
    ...(subscribed !== null && subscribed !== undefined && !subscribed
      ? [
          {
            key: "subscribe",
            label: <Link to="/subscribe">Subscribe</Link>,
          },
        ]
      : []),
    {
      type: "divider",
    },
    ...(guildId && isAdmin
      ? [
          {
            key: "admin",
            label: guildId ? <Link to={`/${guildId}/admin`}>Admin</Link> : null,
          },
        ]
      : []),
    {
      key: "billing",
      label: <Link onClick={goToBilling}>Billing</Link>,
    },
    {
      key: "darkMode",
      label: (
        <Link
          className="flex flex-row items-center gap-3"
          onClick={(e) => {
            e?.stopPropagation();
            setDarkMode((darkMode) => !darkMode);
          }}
        >
          <div className="flex flex-row items-center w-6">
            <FontAwesomeIcon
              className={darkMode ? "text-dark-gray-base" : "text-gray-base"}
              icon={darkMode ? faMoon : faSun}
              size="lg"
            />
          </div>
          <Switch checked={darkMode} onChange={setDarkMode} />
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "signOut",
      danger: true,
      label: <Link to="/signout">Sign Out</Link>,
    },
  ];

  useEffect(() => {
    setExpanded(expandLock);
  }, [expandLock]);

  useEffect(() => {
    if (!expanded) {
      setGuildsOpen(false);
      setUserOpen(false);
    }
  }, [expanded]);

  useEffect(() => {
    if (guilds) {
      let newGuildItems = guilds
        .filter(
          ({ _id, registered }) =>
            _id.toString() !== guildId.toString() && registered
        )
        .map(({ name, _id }) => ({
          key: name,
          label: <Link to={`/${_id}`}>{name}</Link>,
        }));
      setGuildItems(
        newGuildItems.length > 0
          ? newGuildItems
          : [{ key: "none", label: "No Other Guilds", disabled: true }]
      );
    }
  }, [guilds, guildId, location]);

  return (
    <>
      <div
        className={`flex flex-col items-stretch justify-between z-50 h-full overflow-hidden bg-white dark:bg-black ${className} ${
          expandLock
            ? bigSize
            : expanded
            ? `${bigSize} shadow-xl`
            : `${smallSize}`
        }
        
        before:absolute before:-z-10 before:content-[''] before:inset-0 before:bg-gradient-to-tr before:from-[#ffdc00]/20 before:via-transparent before:to-[#0099ff]/20 before:bg-fixed`}
        onMouseEnter={() => {
          if (!expandLock) setExpanded(true);
        }}
        onMouseLeave={() => {
          if (!expandLock) setExpanded(false);
        }}
      >
        <div className="flex flex-col items-stretch gap-0 h-full overflow-hidden">
          <div className="flex flex-row justify-between items-center gap-2 m-2">
            <div className="shrink w-60 flex flex-row items-center gap-2">
              <Eye
                className="shrink-0 object-scale-down h-8 w-8"
                color={constants.colors.secondary}
                strokeWidth="2"
                link
              />
              {guildId && title ? (
                <Dropdown
                  className="shrink flex flex-row cursor-pointer overflow-hidden w-full"
                  menu={{
                    items: guildItems.map((item) => ({
                      ...item,
                      onClick: () => setGuildsOpen(false),
                    })),
                  }}
                  trigger={["click"]}
                  onOpenChange={(open) => setGuildsOpen(open)}
                  open={guildsOpen}
                >
                  <div className="shrink flex flex-row items-center gap-2">
                    <p className="shrink font-normal softer-text text-md whitespace-nowrap overflow-hidden text-ellipsis">
                      {title}
                    </p>
                    <DownOutlined className="text-sm" />
                  </div>
                </Dropdown>
              ) : guildId ? (
                <Spin size="small" />
              ) : null}
            </div>
            <div className="shrink-0 flex flex-row items-center gap-2">
              {guildId && title ? (
                <FontAwesomeIcon
                  className="shrink-0 cursor-pointer softer-text"
                  icon={faUsers}
                  size="sm"
                  onClick={() => setActiveModal("user_access")}
                />
              ) : null}
              <Dropdown
                className="cursor-pointer"
                menu={{ items }}
                trigger={["click"]}
                placement="bottomRight"
                onOpenChange={(open) => setUserOpen(open)}
                open={userOpen}
              >
                <FontAwesomeIcon
                  className="softer-text"
                  icon={faCircleUser}
                  size="lg"
                />
              </Dropdown>
            </div>
          </div>
          <div
            className={`hidden sm:flex flex-col items-stretch gap-4 m-2 mb-12 overflow-hidden ${
              expanded ? "overflow-y-auto" : "overflow-y-hidden"
            }`}
          >
            {tree.length > 0 ? (
              navMap()
            ) : (
              <>
                <Skeleton active className="mt-4" paragraph={{ rows: 5 }} />
                <Skeleton active className="mt-4" paragraph={{ rows: 5 }} />
              </>
            )}
          </div>
        </div>
      </div>
      {guildId ? <AccessModal title={title} guildId={guildId} /> : null}
      {guildId ? <AddRuleModal guildId={guildId} /> : null}
      <div className="fixed left-0 bottom-0 flex flex-row p-2 z-50">
        <Button
          onClick={() => setExpandLock(!expandLock)}
          type="text"
          icon={
            <div
              className={`transition-all ${
                expandLock ? "rotate-90" : "-rotate-90"
              }`}
            >
              <DownOutlined />
            </div>
          }
        />
      </div>
    </>
  );
}
