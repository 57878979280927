import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Select, Skeleton, Space, Spin } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { SiteContext } from "../../Contexts";
import Card from "../../components/Card";
import SetupJiraModal from "../../components/issues/SetupJiraModal";
import APIManager from "../../scripts/APIManager";

export default function Issues() {
  const { guildId, jiraSite, setActiveModal, refreshGuild } =
    useContext(SiteContext);
  const [selectedJiraSite, setSelectedJiraSite] = useState(null);
  const [jiraLinked, setJiraLinked] = useState(null);
  const [jiraSites, setJiraSites] = useState(null);
  const [jiraAccountId, setJiraAccountId] = useState(null);
  const [step2Loading, setStep2Loading] = useState(false);
  const [jiraError, setJiraError] = useState(null);
  const [error, setError] = useState(null);

  const requestJiraAccount = useCallback(() => {
    APIManager.sendRequest("get_jira_account", {}, true).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        setJiraLinked(data.linked);
        setJiraSites(data.sites);
        setJiraAccountId(data.id);
      }
    });
  }, []);

  const linkJiraSite = useCallback(() => {
    APIManager.sendRequest(
      "link_jira_site",
      {
        guild_id: guildId,
        jira_account_id: jiraAccountId,
        site_id: selectedJiraSite,
        site_name: jiraSites.filter(({ id }) => id === selectedJiraSite)[0]
          .name,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        refreshGuild();
      }
    });
  }, [jiraAccountId, selectedJiraSite, jiraSites, guildId, refreshGuild]);

  const createCustomField = useCallback(() => {
    setStep2Loading(true);
    APIManager.sendRequest(
      "create_custom_field",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      setStep2Loading(false);
      if (data.jira_error) setJiraError(data.error);
      else if (data.error) setError(`error: ${data.error}`);
      else {
        refreshGuild();
      }
    });
  }, [guildId, refreshGuild]);

  const confirmFieldSetup = useCallback(() => {
    APIManager.sendRequest(
      "confirm_field_setup",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        refreshGuild();
        setActiveModal(null);
      }
    });
  }, [guildId, refreshGuild, setActiveModal]);

  useEffect(() => requestJiraAccount(), [requestJiraAccount]);

  if (error) return <h1>{error}</h1>;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Issues</h1>
      </div>
      {jiraLinked === null ? (
        <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />
      ) : jiraSite === null ||
        !jiraSite?.custom_field ||
        !jiraSite?.field_setup_confirmed ? (
        <div className="flex flex-row justify-around">
          <div className="flex flex-col gap-5 max-w-xl w-full">
            {!jiraLinked ? (
              <Card className="h-fit p-4 gap-4">
                Please link Jira to continue
              </Card>
            ) : (
              <>
                <Card className="h-fit p-4 gap-4">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                      <h1
                        className={`${jiraSite !== null ? "opacity-30" : ""}`}
                      >
                        <FontAwesomeIcon
                          icon={jiraSite !== null ? faCheckCircle : faCircle}
                          className="pr-3"
                        />
                        <span className="font-normal">Step 1 - </span>
                        Link Jira Site
                      </h1>
                    </div>
                  </div>
                  <div>
                    {jiraSite !== null ? (
                      <p className="opacity-30">COMPLETED</p>
                    ) : (
                      <Space.Compact size="large">
                        <Select
                          className="w-60"
                          size="large"
                          value={selectedJiraSite}
                          onChange={setSelectedJiraSite}
                          placeholder="Select Jira Site"
                          options={jiraSites.map(({ name, avatarUrl, id }) => ({
                            value: id,
                            label: (
                              <div className="flex flex-row items-center gap-2">
                                <img
                                  className="h-5 w-5 rounded"
                                  src={avatarUrl}
                                  alt="Jira page icon"
                                />
                                <p>{name}</p>
                              </div>
                            ),
                          }))}
                        />
                        <Button
                          size="large"
                          type="primary"
                          onClick={linkJiraSite}
                          disabled={jiraSite !== null}
                        >
                          Link Site
                        </Button>
                      </Space.Compact>
                    )}
                  </div>
                </Card>
                <Card className="h-fit p-4 gap-4">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                      <h1
                        className={`${
                          jiraSite === null || jiraSite?.custom_field
                            ? "opacity-30"
                            : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={
                            jiraSite?.custom_field ? faCheckCircle : faCircle
                          }
                          className="pr-3"
                        />
                        <span className="font-normal">Step 2 - </span>Create
                        Custom Field
                      </h1>
                    </div>
                  </div>
                  <div>
                    {jiraSite?.custom_field ? (
                      <p className="opacity-30">COMPLETED</p>
                    ) : step2Loading ? (
                      <Spin className="w-full" size="large" />
                    ) : jiraError ? (
                      // <p>
                      //   You do not have the necessary Jira permissions. An
                      //   administrator of the Jira site must link to SpecifEye.
                      // </p>
                      <p>{jiraError}</p>
                    ) : (
                      <Button
                        size="large"
                        type="primary"
                        onClick={createCustomField}
                        disabled={jiraSite === null || jiraSite?.custom_field}
                      >
                        Create Field
                      </Button>
                    )}
                  </div>
                </Card>
                <Card className="h-fit p-4 gap-4">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                      <h1
                        className={`${
                          jiraSite === null || jiraSite?.field_setup_confirmed
                            ? "opacity-30"
                            : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={
                            jiraSite?.field_setup_confirmed
                              ? faCheckCircle
                              : faCircle
                          }
                          className="pr-3"
                        />
                        <span className="font-normal">Step 3 - </span>Setup
                        Custom Field
                      </h1>
                    </div>
                  </div>
                  <div>
                    {jiraSite?.field_setup_confirmed ? (
                      <p className="opacity-30">COMPLETED</p>
                    ) : (
                      <Button
                        size="large"
                        type="primary"
                        onClick={() => setActiveModal("setup_jira")}
                        disabled={
                          jiraSite === null ||
                          !jiraSite?.custom_field ||
                          jiraSite?.field_setup_confirmed
                        }
                      >
                        Setup Field
                      </Button>
                    )}
                  </div>
                </Card>
              </>
            )}
          </div>
        </div>
      ) : (
        <p className="p-2">Jira setup complete. This page is not ready yet.</p>
      )}

      <SetupJiraModal confirmSetup={confirmFieldSetup} />
    </>
  );
}
