import { Button, Carousel } from "antd";
import { useContext, useMemo, useRef, useState } from "react";
import { SiteContext } from "../../Contexts";
import Modal from "../Modal";

export default function SetupJiraModal(props) {
  const { confirmSetup } = props;
  const { activeModal, setActiveModal } = useContext(SiteContext);
  const carousel = useRef();
  const [currentStep, setCurrentStep] = useState(0);

  const steps = useMemo(
    () => [
      <div className="flex flex-col gap-3 px-px">
        <h1>Welcome to the Issue Manager setup.</h1>
        <p className="text-lg">
          The final step of setup must take place within the Jira page attached
          to SpecifEye.
          <br />
          You will need to add the custom field we've created to a{" "}
          <span className="font-bold">screen</span> within your Jira page. This
          short guide will tell you how to do that.
        </p>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>Navigate to the desired screen configuration page.</h1>
        <p className="text-lg">
          This should be done for any and every screen that you would like to
          see our field on. You will navigate to each based on their{" "}
          <span className="font-bold">project</span> and{" "}
          <span className="font-bold">issue type</span>.
        </p>
        <ol className="text-lg">
          <li>
            Within your desired <span className="font-bold">project</span>, use
            the navigation bar to navigate to "Project settings."
          </li>
          <li>
            Within "Project settings," use the navigation bar to navigate to
            "Issues" &gt; "Layout."
          </li>
          <li>
            Select the desired <span className="font-bold">issue type(s)</span>.
          </li>
        </ol>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>Add the "SpecifEye Relevant Messages" column.</h1>
        <p className="text-lg">
          Within the "Fields" area, select the "SpecifEye Relevant Messages"
          field to add it to the screen. This may require using the search bar.
        </p>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>You are all set!</h1>
        <p className="text-lg">
          Congratulations! You are ready to start using our Jira integration.
        </p>
      </div>,
    ],
    []
  );

  return (
    <Modal
      className="w-full flex flex-col gap-10 mx-32 box-border p-10 overflow-hidden max-w-5xl"
      isActive={activeModal === "setup_jira"}
      onClose={() => {
        setActiveModal(null);
      }}
      onOpen={() => carousel.current?.goTo(0)}
    >
      <Carousel
        className="font-sans m-2"
        dots={false}
        ref={carousel}
        infinite={false}
        beforeChange={(_, next) => {
          if (currentStep !== null) setCurrentStep(next);
        }}
      >
        {steps.map((step, index) => (
          <div key={index}>{step}</div>
        ))}
      </Carousel>
      <div className="flex flex-row justify-between gap-3 m-2">
        <Button
          key="cancel_button"
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setActiveModal(null);
          }}
        >
          Cancel
        </Button>
        <div className="flex flex-row gap-3">
          {currentStep !== 0 ? (
            <Button
              key="back_button"
              size="large"
              className="w-32 font-sans"
              onClick={() => {
                carousel.current?.prev();
              }}
            >
              Previous
            </Button>
          ) : null}
          {currentStep < steps.length - 1 ? (
            currentStep === 0 ? (
              <Button
                key="start_tutorial_button"
                disabled={currentStep + 1 >= steps.length}
                type="primary"
                size="large"
                className="font-sans"
                onClick={() => {
                  carousel.current?.next();
                }}
              >
                Start Tutorial
              </Button>
            ) : (
              <Button
                key="next_button"
                disabled={currentStep + 1 >= steps.length}
                size="large"
                className="w-32 font-sans"
                onClick={() => {
                  carousel.current?.next();
                }}
              >
                Next
              </Button>
            )
          ) : (
            <Button
              key="confirm_setup"
              type="primary"
              size="large"
              className="w-32 font-sans"
              onClick={confirmSetup}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
