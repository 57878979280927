import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import APIManager from "../scripts/APIManager";
import { AccountContext } from "../Contexts";

export default function AtlassianAuth() {
  const { signedIn } = useContext(AccountContext);
  const [jiraCode, setJiraCode] = useState(null);
  const [waitForJiraLink, setWaitForJiraLink] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const redirect_uri =
    window.location.protocol +
    "//" +
    window.location.host +
    (location.pathname === "/"
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname.replace(location.pathname, "")) +
    "/home/atlassian_auth";

  useEffect(() => {
    if (waitForJiraLink === null) {
      const urlParams = new URLSearchParams(location.search);
      let newCode = urlParams.get("code");
      if (newCode) {
        setJiraCode(newCode);
        setWaitForJiraLink(true);
      } else setWaitForJiraLink(false);
    }
  }, [location, waitForJiraLink]);

  const sendJiraCode = useCallback(
    () =>
      APIManager.sendRequest(
        "authenticate_jira",
        {
          jira_code: jiraCode,
          redirect_uri,
        },
        true
      ).then(({ success }) =>
        success
          ? navigate("/home", { replace: true })
          : setWaitForJiraLink(false)
      ),
    [jiraCode, redirect_uri, navigate]
  );

  useEffect(() => {
    if (signedIn && jiraCode) sendJiraCode();
  }, [signedIn, jiraCode, sendJiraCode]);

  return (
    <div className="flex flex-row justify-center items-center w-screen h-screen">
      <div>
        {waitForJiraLink === false ? (
          <>
            <h1 className="text-dark-gray-base">
              Jira code missing or invalid.
            </h1>
            <p className="text-dark-gray-base">Please try again.</p>
          </>
        ) : (
          <>
            <h1 className="text-dark-gray-base">Authenticating Jira.</h1>
            <p className="text-dark-gray-base">This may take a moment...</p>
          </>
        )}
      </div>
    </div>
  );
}
